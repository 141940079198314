export function getCompClassType(componentType: string, uiType?: string) {
	return uiType ? `${componentType}_${uiType}` : componentType
}

export function getClosestCompIdByHtmlElement(htmlElement: HTMLElement): string {
	const viewer_components_id_prefixes = ['comp-', 'TPAMultiSection_', 'TPASection_']
	let closestElement
	for (const prefix of viewer_components_id_prefixes) {
		closestElement = htmlElement.closest(`[id^="${prefix}"]`)
		if (closestElement) {
			break
		}
	}
	return closestElement?.id || ''
}
